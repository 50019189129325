import React from 'react';



const ContactBanner = () => {
  return (
    <section className='banner-page contact'>
      <div className='banner-page--container container'>

        <h1 className="titel-contact">
          Contact
        </h1>
 
      </div>
    </section>
  );
};

export default ContactBanner;
